<template>
  <RealisasiKpiDivisiForm mode="Tambah" module="Realisasi KPI"> </RealisasiKpiDivisiForm>
</template>

<script>
import RealisasiKpiDivisiForm from './form';

const RealisasiKpiDivisiAdd = {
  name: 'RealisasiKpiDivisiAdd',
  components: { RealisasiKpiDivisiForm },
};

export default RealisasiKpiDivisiAdd;
</script>
